import React from "react";

function PrivacyPolicy() {
  return (
    <div className="w-full h-auto flex flex-col justify-start items-center  py-4 bg-[#000029]">
      <div className="h-[auto] w-full flex flex-col justify-start items-center backgroundimg">
        <div className="w-[90%] h-[5vh] flex flex-row justify-between items-center">
          <p className="text-3xl text-white font-[600] cursor-pointer">
            ZanZoom
          </p>
          <div className="bg-[#0475FF] px-2 py-2 rounded-[10px]">
            <p className="text-white">Contact Us</p>
          </div>
        </div>
        <div className="flex w-[90%] mt-10 flex-col justify-center items-start">
          <div className="flex flex-row justify-center w-full items-center">
            <p className="text-4xl text-white justify-center items-center font-[600] cursor-pointer">
              Zanzoom Privacy Policy
            </p>
          </div>
          <div>
            <p className="text-white text-xl mt-10">
              Zanzoom ("we," "our," or "us") is committed to protecting your
              privacy. This Privacy Policy explains how we collect, use, and
              share your information when you use the Zanzoom Driver App
              ("App"). By using the App, you agree to the collection and use of
              information as outlined in this Privacy Policy.
            </p>
          </div>
          <div className="w-full mt-8 mb-12 text-white">
            <p className="text-4xl font-semibold mt-6 mb-2">
              1. Information We Collect
            </p>

            <div className="ml-8">
              <p className="text-2xl font-medium mt-4 mb-2">
                1.1 Personal Information
              </p>
              <ul className="list-disc pl-6 flex flex-col gap-3 text-xl">
                <li>Full Name</li>
                <li>Phone Number</li>
                <li>Email Address</li>
                <li>Profile Photo</li>
                <li>Government ID (e.g., Driving License, Aadhaar, etc.)</li>
                <li>
                  Vehicle Details (e.g., Registration Number, Model, Color)
                </li>
              </ul>

              <p className="text-2xl font-medium mt-4 mb-2">
                1.2 Location Data
              </p>
              <ul className="list-disc pl-6 flex flex-col gap-3 text-xl">
                <li>
                  We continuously track and collect your real-time GPS location
                  when the app is active.
                </li>
                <li>
                  Location tracking is required for ride matching, navigation,
                  and safety purposes.
                </li>
              </ul>
              <p className="text-2xl font-medium mt-4 mb-2">
                1.3 Ride and Transaction Data
              </p>
              <ul className="list-disc pl-6">
                <li className="text-xl">
                  Details of rides you accept, start, and complete.
                </li>
                <li className="text-xl">
                  Distance traveled, fare calculation, and payment history.
                </li>
              </ul>

              <p className="text-2xl font-medium mt-4 mb-2">
                1.4 Device and Log Information
              </p>
              <ul className="list-disc pl-6">
                <li className="text-xl">
                  Device model, OS version, unique device identifiers.
                </li>
                <li className="text-xl">
                  IP address, app crash reports, and app usage data.
                </li>
              </ul>
            </div>

            <p className="text-4xl font-semibold mt-6 mb-2">
              2. How We Use Your Information
            </p>
            <ul className="list-disc flex flex-col gap-3 ml-6 pl-6">
              <li className="text-xl">
                Matching drivers with nearby ride requests.
              </li>
              <li className="text-xl">
                Providing navigation and route suggestions.
              </li>
              <li className="text-xl">Ensuring passenger and driver safety.</li>
              <li className="text-xl">
                Processing payments and generating earnings reports.
              </li>
              <li className="text-xl">
                Preventing fraud and improving service quality.
              </li>
              <li className="text-xl">
                Sending notifications about ride requests, updates, or
                promotions.
              </li>
            </ul>

            <p className="text-4xl font-semibold mt-6 mb-2">
              3. Location Tracking and Recording
            </p>
            <ul className="list-disc flex flex-col gap-3 ml-6 pl-6">
              <li className="text-xl">
                Continuous GPS tracking is required when the app is active.
              </li>
              <li className="text-xl">
                We may record and store your location history for safety, legal,
                and operational purposes.
              </li>
            </ul>

            <p className="text-4xl font-semibold mt-6 mb-2">
              4. Data Sharing & Disclosure
            </p>
            <p className="text-xl">
              We do not sell your data. However, we may share your information
              with:
            </p>
            <ul className="list-disc flex flex-col gap-3 ml-6 pl-6">
              <li className="text-xl">
                <strong>Passengers:</strong> Your name, profile photo, vehicle
                details, and real-time location.
              </li>
              <li className="text-xl">
                <strong>Law Enforcement & Authorities:</strong> If required by
                law or for security purposes.
              </li>
              <li className="text-xl">
                <strong>Third-Party Services:</strong> Payment processors, map
                providers, and analytics tools.
              </li>
            </ul>

            <p className="text-4xl font-semibold mt-6 mb-2">
              5. Data Security & Retention
            </p>
            <p className="text-xl">
              We use strong encryption and security measures to protect your
              data from unauthorized access. Your information is safeguarded
              both in transit and at rest using advanced protocols. We retain
              data only as long as needed for legal, safety, and operational
              purposes. Once no longer required, we securely delete or anonymize
              it. Our commitment to privacy ensures your data is handled with
              the highest level of security.
            </p>

            <p className="text-4xl font-semibold mt-6 mb-2">
              6. Your Rights & Choices
            </p>
            <ul className="list-disc flex flex-col gap-3 ml-6 pl-6">
              <li className="text-xl">
                <strong>Access & Update:</strong> You can view and update your
                profile information.
              </li>
              <li className="text-xl">
                <strong>Location Permissions:</strong> Disabling location
                tracking prevents receiving ride requests.
              </li>
              <li className="text-xl">
                <strong>Account Deletion:</strong> Contact us at [support email]
                to request account deletion.
              </li>
            </ul>

            <p className="text-4xl font-semibold mt-6 mb-2">
              7. Changes to This Privacy Policy
            </p>
            <p className="text-xl">
              We may update this policy from time to time. Any changes will be
              communicated via the app or email.
            </p>

            <p className="text-4xl font-semibold mt-6 mb-2">8. Contact Us</p>
            <p className="text-xl">
              For questions or concerns about this Privacy Policy, contact us
              at:
            </p>
            <div className="flex flex-col gap-4 mt-8">
            <p className="text-xl">📧 Email: zanzoomiitm@gmail.com</p>
            <p className="text-xl">📍 Address: Bweleo, Zanzibar, Tanzania</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
